<template>
  <div class="examplesModal">
    <i class="el-icon-close" @click="$emit('hidden')"></i>
    <div class="examplesContant">
      <img v-for="(item, index) in imgList" :key="index" :src="item" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  // props: {
  //   showIndex: {
  //     type: Number,
  //     default: () => -1
  //   }
  // },
  data() {
    return {
      imgList: []
    }
  },
  methods: {
    getImgList(index) {
      switch (index) {
        case 0:
          this.imgList = [
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/yjpz1.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/yjpz2.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/yjpz3.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/yjpz4.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/yjpz5.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/yjpz6.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/yjpz7.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/yjpz8.png'
          ]
          break
        case 1:
          this.imgList = [
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/order-send-shop1.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/order-send-shop2.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/order-send-shop3.png'
          ]
          break
        case 2:
          this.imgList = [
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/shsqtz1.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/shsqtz2.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/shsqtz3.png'
          ]
          break
        case 3:
          this.imgList = [
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/wait-pay1.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/wait-pay2.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/wait-pay3.png'
          ]
          break
        case 4:
          this.imgList = [
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/order-cancal1.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/order-cancal2.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/order-cancal3.png'
          ]
          break
        case 5:
          this.imgList = [
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/pay-success1.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/pay-success2.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/pay-success3.png'
          ]
          break
        case 6:
          this.imgList = [
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/order-send1.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/order-send2.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/order-send3.png'
          ]
          break
        case 7:
          this.imgList = [
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/dzjjzdqrshtz1.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/dzjjzdqrshtz2.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/dzjjzdqrshtz3.png'
          ]
          break
        case 8:
          this.imgList = [
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/tksqbhtz1.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/tksqbhtz2.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/tksqbhtz3.png'
          ]
          break
        case 9:
          this.imgList = [
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/tkcgtz1.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/tkcgtz2.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/tkcgtz3.png'
          ]
          break
        case 10:
          this.imgList = [
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/tksbtz1.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/tksbtz2.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/tksbtz3.png'
          ]
          break
        case 11:
          this.imgList = [
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/cz-success1.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/cz-success2.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/cz-success3.png'
          ]
          break
        case 12:
          this.imgList = [
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/txsqtz1.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/txsqtz2.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/txsqtz3.png'
          ]
          break
        case 13:
          this.imgList = [
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/txcgtz1.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/txcgtz2.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/txcgtz3.png'
          ]
          break
        case 14:
          this.imgList = [
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/txsbtz1.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/txsbtz2.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/txsbtz3.png'
          ]
          break
        case 15:
          this.imgList = [
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/tkpz1.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/tkpz2.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/tkpz3.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/tkpz4.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/tkpz5.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/tkpz6.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/tkpz7.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/tkpz8.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/tkpz9.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/tkpz10.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/tkpz11.png'
          ]
          break
        case 16:
          this.imgList = [
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/dxqm1.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/dxqm7.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/dxqm2.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/dxqm3.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/dxqm4.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/dxqm5.png',
            'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/chineseImg/dxqm6.png'
          ]
          break
        default:
          break
      }
    }
  }
}
</script>

<style lang="less" scoped>
.examplesModal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  .examplesContant {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0px;
      height: 5px;
      /**/
    }
    img {
      width: 100%;
      margin-top: 20px;
    }
    img:last-child {
      margin-bottom: 30vh;
    }
  }
  .el-icon-close {
    position: absolute;
    right: 70px;
    top: 30px;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
  }
}
</style>
