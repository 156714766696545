<template>
    <div style="height: 100%">
        <div class="steps" v-for="(item, index) in authorizationArr" :key="index">
            <div class="top">
                <div class="left" v-if="authorizationSteps <= index"
                    :style="{ background: authorizationSteps === index ? '#00ca5a' : '#D8D8D8' }">
                    {{ index + 1 }}
                </div>
                <div class="left1" v-else>
                    <i class="el-icon-check"></i>
                </div>
                <div class="mid">
                    {{ item.stageTitle }}
                    <span v-if="index === 1" class="liveTitle">直播权限：</span>
                    <span v-if="index === 1" class="openLive" @click="openLive">{{ dataInfo.is_live ? '关闭' : '开启'
                    }}</span>
                </div>
                <div class="right" v-if="index != 2">
                    <div v-if="authorizationSteps <= index" class="canBtn" :style="{
                        color: authorizationSteps === index ? '#fff' : '#999999',
                        background: authorizationSteps === index ? '#00ca5a' : '#EBEBEB',
                    }" @click="btnFunction(index)">
                        {{ item.btnTxt }}
                    </div>
                    <div v-else class="rightElse">
                        <div v-if="index === 0">
                            <i class="el-icon-check"></i>
                            已填写
                        </div>
                        <div v-if="index === 1">
                            <i class="el-icon-check"></i>
                            已设置
                        </div>
                        <div v-if="index === 2">
                            <i class="el-icon-check"></i>
                            已上传
                        </div>
                        <div v-if="index === 3">
                            <i class="el-icon-check"></i>
                            已提审
                        </div>
                        <div v-if="index === 4">
                            <i class="el-icon-check"></i>
                            已发布
                        </div>
                    </div>
                </div>
                <div class="right" v-else>
                    <!--  -->
                    <div v-if="authorizationSteps > index && dataInfo.version_new.version && dataInfo.version === dataInfo.version_new.version"
                        class="rightElse">
                        <div>
                            <i class="el-icon-check"></i>
                            已上传
                        </div>
                    </div>
                    <!--  -->
                    <div v-else-if="authorizationSteps < index" class="canBtn"
                        :style="{ color: '#999999', background: '#EBEBEB' }">
                        {{ item.btnTxt }}
                    </div>
                    <!--   -->
                    <div v-else-if="authorizationSteps == index || dataInfo.version || dataInfo.version !== dataInfo.version_new.version"
                        @click="btnFunction(index)" class="canBtn" :style="{ color: '#fff', background: '#00ca5a' }">
                        {{ item.btnTxt }}
                    </div>
                </div>
            </div>
            <div class="bot">
                <div class="left" v-if="index !== authorizationArr.length - 1">
                    <div class="xian" :style="{ background: authorizationSteps >= index + 1 ? '#00ca5a' : '#eeeeee' }">
                    </div>
                </div>
                <div class="mid">
                    <div v-if="index === 0">
                        <p>已授权成功，补全相关信息。</p>
                        <p class="fc999" v-if="dataInfo.appstatus >= 1">
                            小程序名称：
                            <b style="color: #333">{{ dataInfo.name }}</b>
                        </p>
                    </div>
                    <div v-if="index === 1">
                        <p>选择微信支付，在微信支付平台开通微信商户号，并配置相关信息</p>
                        <p class="fc999" v-if="dataInfo.business_info.open_pay">
                            关联商户号：
                            <b style="color: #333">{{ dataInfo.m_id }}</b>
                        </p>
                    </div>
                    <div v-if="index === 2">
                        <p>上传状态：{{ dataInfo.appstatus >= 2 ? '已上传' : '未上传' }}</p>
                        <p v-if="dataInfo.appstatus >= 2">
                            当前版本：
                            <b style="color: #333">{{ dataInfo.version }}</b>
                        </p>
                        <p>
                            最新版本：
                            <b :style="{
                                color: dataInfo.version === dataInfo.version_new.version ? '#333' : 'red',
                            }">
                                {{ dataInfo.version_new.version || '请等待管理员更新版本' }}({{ dataInfo.version ===
                                        dataInfo.version_new.version ? '已是最新版本' : '请立即上传最新版本'
                                }})
                            </b>
                        </p>
                    </div>
                    <div v-if="index === 3">
                        <p>提交微信审核（最长14个工作日），审核通过后即可立即发布版本</p>
                        <p>审核状态：{{ examineTxt }}</p>
                        <p v-if="rejectWhy.length > 0" @click="lookList" class="fc852">查看记录</p>
                    </div>
                    <div v-if="index === 4">
                        <p>
                            发布状态：
                            <b>{{ authorizationSteps == 5 ? `发布成功` : '暂未发布' }}</b>
                        </p>
                    </div>
                </div>
                <div class="right">
                    <div v-if="index === 0 && authorizationSteps > 0">
                        <span @click="lookOne(index)">查看</span>
                    </div>
                    <div v-if="index === 1 && authorizationSteps > 1">
                        <span @click="lookOne(index)">查看</span>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="查看记录" :visible.sync="showList" width="52%">
            <div>
                <el-table :data="rejectWhy" style="width: 100%" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                    <el-table-column label="审核时间" width="180">
                        <template v-slot="{ row }">
                            {{ row.create_time | getDate() }}
                        </template>
                    </el-table-column>
                    <el-table-column label="审核结果" width="180">
                        <template v-slot="{ row }">
                            {{ row.op == '审核成功' || row.op == '已撤回' ? row.op : '审核被拒绝' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="反馈详情" prop="op"></el-table-column>
                </el-table>
                <Paging :total="rejectWhyTotal" :page="page" :pageNum="pageNum" @updatePageNum="updateData"></Paging>
            </div>
        </el-dialog>
        <el-dialog title="基础配置" :visible.sync="basicSet" width="52%">
            <WxBasics :storeId="storeId" @updateBasicsSu="updateBasicsSu" ref="WxBasics" :dataInfo="dataInfo">
            </WxBasics>
            <span slot="footer" class="dialog-footer">
                <el-button @click="basicSet = false">取 消</el-button>
                <el-button type="primary" @click="updateBasics">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="支付配置" :visible.sync="WxPay" width="52%">
            <WxPay :storeId="storeId" @WxPaySureSu="WxPaySureSu" :islook="islook" ref="WxPay"></WxPay>
            <span v-if="!islook" slot="footer" class="dialog-footer">
                <el-button @click="WxPay = false">取 消</el-button>
                <el-button type="primary" @click="WxPaySure">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="查看记录" :visible.sync="showList" width="52%">
            <div>
                <el-table :data="rejectWhy" style="width: 100%" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                    <el-table-column label="审核时间" width="180">
                        <template v-slot="{ row }">
                            {{ row.create_time | getDate() }}
                        </template>
                    </el-table-column>
                    <el-table-column label="审核结果" width="180">
                        <template v-slot="{ row }">
                            {{ row.op == '审核成功' || row.op == '已撤回' ? row.op : '审核被拒绝' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="反馈详情" prop="op"></el-table-column>
                </el-table>
                <Paging :total="rejectWhyTotal" :page="page" :pageNum="pageNum" @updatePageNum="updateData"></Paging>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getDateformat } from '../../../../util/getDate';
import Paging from '@/components/paging';
import WxBasics from './wxBasics.vue';
import WxPay from './wxPay.vue';
export default {
    props: {
        dataInfo: {
            type: Object,
            default: () => { },
        },
        storeId: {
            type: Number,
            default: 0
        }
    },
    components: {
        Paging,
        WxBasics,
        WxPay,
    },
    filters: {
        getDate (index) {
            return getDateformat(index);
        },
    },
    watch: {
        examineStatus (val) {
            if (val == 2 || val == 4) {
                this.authorizationArr[3].btnTxt = '撤销审核';
            } else {
                this.authorizationArr[3].btnTxt = '立即审核';
            }
        },
        WxPay (val) {
            if (!val) {
                setTimeout(() => {
                    this.islook = 0;
                }, 200);
            }
        },
        basicSet (val) {
            if (!val) {
                setTimeout(() => {
                    this.islook = 0;
                }, 200);
            }
        },
    },
    data () {
        return {
            // 微信支付配置弹框
            WxPay: false,
            // 基础配置弹框
            basicSet: false,
            // 步骤状态
            authorizationSteps: 0,
            // 审核步骤合集
            authorizationArr: [
                { stageTitle: '已授权小程序，立即补全信息', btnTxt: '补全信息' },
                { stageTitle: '授权成功，立刻支付配置', btnTxt: '支付设置' },
                { stageTitle: '配置成功，立即上传小程序', btnTxt: '立即上传' },
                { stageTitle: '上传小程序成功，立即提交审核', btnTxt: '提交审核' },
                { stageTitle: '审核成功，立即发布小程序', btnTxt: '立即发布' },
            ],
            // 审核信息
            examineTxt: '暂未提交审核',
            examineStatus: -1,
            // 审核记录
            rejectWhy: [],
            rejectWhyTotal: 0,
            page: 1,
            pageNum: 10,
            showList: false,
            islook: 0,
        };
    },
    created () {
        this.matchingSteps(this.dataInfo.appstatus);
        this.getRejectList();
        if (this.dataInfo.appstatus == 3 || this.dataInfo.appstatus == 4 || this.dataInfo.appstatus == 5) {
            this.checkStatus();
        }
    },
    methods: {
        // 修改微信支付配置成功
        WxPaySureSu (m_id) {
            this.dataInfo.m_id = m_id;
        },
        // 确认修改微信支付配置
        WxPaySure () {
            this.$refs.WxPay.preservation();
            this.matchingSteps(this.dataInfo.appstatus++);
        },
        // 确认修改基础配置
        updateBasics () {
            this.$refs.WxBasics.preservation();
        },
        // 基础配置修改成功
        updateBasicsSu (secret) {
            this.dataInfo.secret = secret;
            // this.dataInfo.account = account
            this.matchingSteps(this.dataInfo.appstatus++);
        },
        // 关闭弹框
        closeDia () {
            this.islook = 0;
        },
        // 查看配置弹框
        lookOne (index) {
            this.islook = 1;
            if (index == 0) {
                this.basicSet = true;
                this.$nextTick(() => {
                    this.$refs.WxBasics.copyMy = this.dataInfo.secret;
                    // this.$refs.WxBasics.account = this.dataInfo.account
                });
            } else if (index == 1) {
                this.WxPay = true;
                this.$nextTick(() => {
                    this.$refs.WxPay.getInfo();
                });
            }
        },
        // 点击配置按钮
        btnFunction (index) {
            if (index === 0) {
                this.basicSet = true;
                this.$nextTick(() => {
                    this.$refs.WxBasics.copyMy = this.dataInfo.secret;
                    this.$refs.WxBasics.account = this.dataInfo.account;
                });
            } else if (index == 1) {
                this.WxPay = true;
                this.$nextTick(() => {
                    this.$refs.WxPay.getInfo();
                });
            } else if (index == 2) {
                this.onceUpLoad();
            } else if (index == 3) {
                this.getStatus();
            } else if (index == 4) {
                this.release();
            }
        },
        // 打开审查记录弹框
        lookList () {
            this.getRejectList();
            this.showList = true;
        },
        // 检测审核状态
        checkStatus () {
            let that = this;
            let path = this.$api.set.wxAuditStatus
            let obj = {}
            if (this.storeId) {
                path = this.$api.serviceProvider.getAuditStatus
                obj.store_id = this.storeId
            }
            this.$axios.post(path, obj).then(res => {
                if (res.code == 0) {
                    if (res.result.errcode == 0) {
                        this.examineStatus = res.result.status;
                        switch (res.result.status) {
                            case 0:
                                this.examineTxt = '审核成功';
                                this.dataInfo.appstatus = 4;
                                break;
                            case 1:
                                this.examineTxt = '审核被拒绝';
                                this.dataInfo.appstatus = 5;
                                break;
                            case 2:
                                this.examineTxt = '审核中';
                                break;
                            case 3:
                                this.examineTxt = '已撤回';
                                this.dataInfo.appstatus = 2;
                                break;
                            case 4:
                                this.examineTxt = '审核延后';
                                break;
                            default:
                                break;
                        }
                        this.matchingSteps(this.dataInfo.appstatus);
                    } else {
                        that.$message.error(`${res.result.errmsg}`);
                    }
                } else {
                    that.$message.error(`服务器错误`);
                }
            });
        },
        // 根据授权状态判断步骤
        matchingSteps (status) {
            if (!this.dataInfo.key) {
                // 填写基本信息
                this.authorizationSteps = 0;
            } else if (this.dataInfo.business_info.open_pay == 0) {
                // 没有填写微信支付配置
                this.authorizationSteps = 1;
            } else if (status == 1) {
                // 未上传
                this.authorizationSteps = 2;
            } else if (status == 2 || status == 3 || status == 5) {
                // 已上传 已提交审核 审核失败
                this.authorizationSteps = 3;
            } else if (status == 4) {
                // 未发布
                this.authorizationSteps = 4;
            } else if (status == 6) {
                // 已发布
                this.authorizationSteps = 5;
            }
        },
        // 立即上传
        onceUpLoad () {
            if (this.authorizationSteps < 2 && this.dataInfo.version === this.dataInfo.version_new.version) return;
            if (!this.dataInfo.version_new.version) {
                this.$message.warning('等待管理员更新版本');
                return;
            }
            let that = this;
            let path = this.$api.set.wxCommit
            let obj = {}
            if (this.storeId) {
                path = this.$api.serviceProvider.commit
                obj.store_id = this.storeId
            }
            this.$axios
                .post(path, obj)
                .then(res => {
                    if (res.code == 0) {
                        if (res.result.errcode == 0) {
                            that.$message({
                                message: '上传成功',
                                type: 'success',
                            });
                            this.dataInfo.appstatus = 2;
                            this.dataInfo.version = this.dataInfo.version_new.version;
                            this.matchingSteps(this.dataInfo.appstatus);
                        } else {
                            that.$message({
                                message: `上传状态:${res.result.errmsg}`,
                                type: 'warning',
                            });
                        }
                    }
                })
                .catch(err => { });
        },
        // 点击提交审核
        getStatus () {
            let that = this;
            if (this.authorizationSteps !== 3) return;
            if (this.examineStatus == 2 || this.examineStatus == 4) {
                let path = this.$api.set.wxundocodeaudit
                let obj = {}
                if (this.storeId) {
                    path = this.$api.serviceProvider.undocodeaudit
                    obj.store_id = this.storeId
                }
                // 撤销审核
                this.$axios.post(path, obj).then(res => {
                    if (res.code == 0) {
                        if (res.result.errcode == 0) {
                            that.$message({
                                message: '撤销审核成功',
                                type: 'success',
                            });
                            this.checkStatus();
                        } else {
                            that.$message({
                                message: `${res.result.errmsg}`,
                                type: 'error',
                            });
                        }
                    } else {
                        that.$message({
                            message: '系统错误',
                            type: 'error',
                        });
                    }
                });
                return;
            }
            let path = this.$api.set.submitAudit
            let obj = {}
            if (this.storeId) {
                path = this.$api.serviceProvider.submitAudit
                obj.store_id = this.storeId
            }
            this.$axios.post(path, obj).then(res => {
                if (res.code == 0) {
                    if (res.result.errcode == 0) {
                        that.$message({
                            message: '已成功提交审核，工作人员将在五个工作日内完成审核',
                            type: 'success',
                        });
                        this.dataInfo.appstatus = 3;
                        this.matchingSteps(this.dataInfo.appstatus);
                        this.checkStatus();
                    } else {
                        that.$message({
                            message: `上传状态:${res.result.errmsg}`,
                            type: 'warning',
                        });
                    }
                } else {
                    that.$message({
                        message: '提交失败',
                        type: 'error',
                    });
                }
            });
        },
        // 立即发布
        release () {
            if (this.authorizationSteps !== 4) return;
            let that = this;
            let path = this.$api.set.wxRelease
            let obj = {}
            if (this.storeId) {
                path = this.$api.serviceProvider.release
                obj.store_id = this.storeId
            }
            this.$axios.post(path, obj).then(res => {
                if (res.code == 0) {
                    if (res.result.errcode == 0) {
                        that.$message({
                            message: '发布成功',
                            type: 'success',
                        });
                        this.dataInfo.appstatus = 6;
                        this.matchingSteps(this.dataInfo.appstatus);
                    } else {
                        that.$message({
                            message: '发布失败',
                            type: 'error',
                        });
                    }
                } else {
                    that.$message({
                        message: '系统错误',
                        type: 'error',
                    });
                }
            });
        },
        // 获取审核记录列表
        getRejectList () {
            let that = this;
            let path = this.$api.set.rejectList
            let obj = {
                page: that.page,
                rows: that.pageNum,
            }
            if (this.storeId) {
                path = this.$api.serviceProvider.getThirdLog
                obj.store_id = this.storeId
            }
            this.$axios
                .post(path, obj)
                .then(res => {
                    if (res.code == 0) {
                        this.rejectWhy = res.result.list;
                        this.rejectWhyTotal = res.result.total;
                    }
                });
        },
        updateData (val, status) {
            if (status == 0) {
                this.pageNum = val;
            } else {
                this.page = val;
            }
            this.getRejectList();
        },
        openLive () {
            let path = this.$api.set.setWxapp
            let obj = {
                is_live: this.dataInfo.is_live ? 0 : 1,
            }
            if (this.storeId) {
                path = this.$api.serviceProvider.setWxapp
                obj.store_id = this.storeId
            }
            this.$axios
                .post(path, obj)
                .then(res => {
                    if (res.code === 0) {
                        this.$message.success(`${this.dataInfo.is_live ? '成功关闭直播' : '成功开启直播'}`);
                        this.dataInfo.is_live = this.dataInfo.is_live ? 0 : 1;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
    },
};
</script>

<style lang="less" scoped>
.cupo {
    cursor: pointer;
}

.fc666 {
    color: #666 !important;
}

.fc409 {
    color: #409eff;
}

.fc999 {
    color: #999 !important;
}

.fc852 {
    color: #00b852 !important;
    cursor: pointer;
}

.canBtn {
    width: 96px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    border-radius: 2px;
    cursor: pointer;
    user-select: none;
}

.steps {
    width: 100%;

    &>div {
        display: flex;
        justify-content: space-between;

        .left {
            flex: 0 0 24px;
        }

        .mid {
            flex: 1;
            padding: 0 10px;
        }

        .right {
            flex: 0 0 96px;
        }
    }

    .top {
        align-items: center;

        .left {
            height: 24px;
            text-align: center;
            line-height: 24px;
            color: #fff;
            border-radius: 50%;
        }

        .left1 {
            width: 24px;
            height: 24px;
            text-align: center;
            line-height: 22px;
            border: 2px solid #00b852;
            border-radius: 50%;

            i {
                color: #00b852;
                font-weight: bold;
            }
        }

        .mid {
            font-size: 16px;
            font-weight: 500;
            color: #333333;
        }

        .rightElse {
            flex-wrap: nowrap;

            &>div {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                i {
                    width: 16px;
                    height: 16px;
                    background: #06ba56;
                    border-radius: 50%;
                    color: #fff;
                    font-size: 12px;
                    line-height: 16px;
                    text-align: center;
                    font-weight: bold;
                    margin-right: 8px;
                }
            }
        }
    }

    .bot {
        min-height: 100px;

        .left {
            .xian {
                width: 2px;
                min-height: 80px;
                margin: 10px auto;
            }
        }

        .mid {
            &>div {
                p {
                    font-size: 14px;
                    color: #666;
                    margin-top: 5px;
                }
            }
        }

        .right {
            display: flex;
            flex-direction: column;

            div {
                text-align: right;
            }

            span {
                cursor: pointer;
                user-select: none;
                align-self: flex-end;
                font-size: 14px;
                color: #06ba56;
            }
        }
    }

    .bot:last-child {
        min-height: 50px;
    }
}

.el-table {
    margin-bottom: 10px;

    /deep/ .el-table__body-wrapper {
        height: 40vh;
        overflow: hidden;
        overflow-y: auto;
    }
}

.liveTitle {
    margin-left: 20px;
    user-select: none;
    font-size: 14px;
}

.liveStatus {
    user-select: none;
    font-size: 14px;
    margin-right: 20px;
}

.openLive {
    color: #1467ff;
    cursor: pointer;
    user-select: none;
    font-size: 14px;
}
</style>
